import React, { useEffect, useState } from 'react'
import { RetailTrainingService } from '../services/Services'
import { useOktaAuth } from '@okta/okta-react';
import { oktaAuth } from '../config';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { UseMessage } from './Alert';

export default function SurveyPage(props) {

    const { authState } = useOktaAuth();
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    const [eventTitle, setEventTitle] = useState('Survey')
    const [surveyId, setSurveyId] = useState(null)
    const [eventId, setEventId] = useState(null)
    const [message, setMessage] = useState(null)
    const [auth, setAuth] = React.useState('null')
    let rtS = new RetailTrainingService(props.authState)
    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/survey/" + window.location.pathname.split('/survey/')[1] });
    useEffect(() => {
        if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken) {

            let id = window.location.pathname.split('/survey/')[1].replace('/', '')
            if (id)
                rtS.getSurveyQuestions(id).then((res) => {
                    if (res.Message)
                        setMessage(res.Message)
                    else if (res.message)
                        setMessage(res.message)
                    else {
                        setQuestions(res.survey.questions)
                        setEventTitle(res.event_title)
                        setSurveyId(res.survey.id)
                        setEventId(id)
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        if (error.response.data.code === "unauthorized") {
                            setAuth('notauth')
                        }
                        else if (error.response.data.code === "expired_token") {
                            if (!sessionStorage.getItem('ref')) {
                                sessionStorage.setItem('ref', 'true')
                                window.location.reload()
                            }
                        }
                    }
                }).finally(() => {
                    setLoading(false)
                })
        }
        else if (!authState?.isAuthenticated)
            if (!window.location.href.includes('code') && window.location.pathname && window.location.pathname.split('/').length > 0) {
                localStorage.setItem('tmpID', window.location.pathname.split('/')[1])
                Promise.resolve(loggingIn())
            }

    }, [])
    const setResponse = (q, e, type) => {
        questions.map((el) => {
            if (el.id == q.id)
                q[type] = e.target.value
        })
        setQuestions([...questions])
    }
    const sendResponse = () => {
        let emptyResponse = false
        let responses = []
        questions.map((q) => {
            if (!q.likert_response && !q.text_response && q.required)
                emptyResponse = true
            responses.push({
                "question_id": q.id,
                "likert_response": q.likert_response ? q.likert_response : null,
                "text_response": q.text_response ? q.text_response : null
            })
        })
        let body = {
            survey_id: surveyId,
            responses: responses
        }
        if (!emptyResponse)
            rtS.sendResponse(eventId, body).then((message) => {
                setMessage(message)
            }).catch((err) => {
                UseMessage(400, err)
            })
        else
            UseMessage(400, "Please answer the required questions")
    }
    let questionsForm = ''
    if (questions)
        questionsForm = questions.map((q, index) => <div> <br />
            <div Style={'padding-bottom:5px; display:flex'}>{q.required && !q.likert_response && !q.text_response ? <div className='required_flag'>*</div> : ''}{index + 1}. {q.question}</div>

            {q.type === 'open' ?
                <textarea className='survey-input' placeholder='Response' onChange={(e) => setResponse(q, e, 'text_response')}></textarea>

                :
                q.type === 'likert_scale' ?
                    <Rating
                        value={q['likert_response'] ?? null}
                        onChange={(e) => setResponse(q, e, 'likert_response')}
                        name="simple-controlled"
                        max={q.likert_max_value}
                        color='white'
                        emptyIcon={<StarIcon style={{ opacity: 0.55, color: 'grey', height: 30, width: 30 }} fontSize="inherit" />}
                        icon={<StarIcon style={{ opacity: 1, color: 'black', height: 30, width: 30 }} fontSize="inherit" />}
                    />
                    : ''}
        </div>)
    return (
        loading ? <div className="loader-cont">
            <div className="loader1"></div>
        </div> :
            auth === 'notauth' ?
                <div className="homepage">
                    <div className='notauth'> You are not authorized!</div>
                </div>
                :
                !message ? <div className='survey-container'>
                    <div className='survey-form'>
                        <div className='survey-title'>{eventTitle} </div>
                        <div className='survey-subtitle'>Rate your learning experience</div>
                        <hr Style={"margin-top: 20px;"} />
                        <div className="questions-container" >
                            {questionsForm}
                        </div>
                        <br />
                        <div Style={'text-align: -webkit-center;'}>
                            <div className='survey-send-button' onClick={sendResponse}>Send
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <div className='track-container' >
                        <div className='track-message'>{message}
                        </div>
                    </div>

    )
}