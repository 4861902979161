import axios from 'axios';
import { Component } from 'react';
import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { UseMessage } from '../view/Alert';
import App from '../App';

function getApiUrl() {
    if (window.location.hostname === 'retailtrainingcalendar.gucci.cn' || window.location.hostname === 'lucenewscalendar.gucci.cn') {
        return 'https://training-api.gucci.cn/digitalcalendar'
    }
    else if (window.location.hostname === 'retailtrainingcalendar.gucci.com' || window.location.hostname === 'lucenewscalendar.gucci.com') {
        return "https://training-api.globaltech.gucci/digitalcalendar"
    }
    else {
        return 'https://hrintegration.globalbit.gucci/trainingapi_staging/digitalcalendar'
    }
}
function getAccessToken() {
    return JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken
}
export class RetailTrainingService extends Component {

    //get events by date or search text

    async getEvents(options) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/v3/events', options).then((response) => {
                //setLoading(false)
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                reject(error)
            });
        })
    }

    async getUser() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/user').then(async (response) => {

                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async getTeamUsers(eventId) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/events/' + eventId + '/suggest').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                reject(error)
            });
        })
    }
    async suggestEvent(eventId, value) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/v3/events/' + eventId + '/suggest', value).then(async (response) => {
                UseMessage(200, 'Event suggested')
                resolve(response)
            }).catch(function (error) {
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                UseMessage(200, error.response.data.message)
                reject(error)
            });
        })
    }
    async getEventByID(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/v3/events/' + id).then(async (response) => {

                //setLoading(false)
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                reject(error)
            });
        })
    }
    async trackPage(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.put(getApiUrl() + '/v3/track-participant/' + id).then(async (response) => {
                resolve(response.data.message)
            }).catch(function (error) {
                console.log(error)
                if (error && error.response && error.response.status == 401)
                    reject(error)
                else if (error.response.data.Message)
                    resolve(error.response.data.Message)
                else if (error.response.data.message)
                    resolve(error.response.data.message)
            });
        })
    }

    async getMaterialFile(event_id, file) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/v3/event/' + event_id + '/material/' + file.id + '/download', { responseType: 'blob' }).then(function (response) {
                const type = response.headers['content-type']

                const blob = new Blob([response.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = file.filename
                link.click()

            }).catch(function (error) {
                if (error.response.data.Message)
                    resolve(error.response.data.Message)
                else if (error.response.data.message)
                    resolve(error.response.data.message)

            });
        })
    }

    async getSurveyQuestions(event_id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/v3/event/' + event_id + '/survey').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                //  if (error.response.status == 401) {
                //UseAlert(401)
                //setLoading(false)
                // }
                if (error && error.response && error.response.status == 401)
                    reject(error)
                if (error.response.data)
                    resolve(error.response.data)



            });
        })
    }
    async getWishlist(setWishlist, setWishlistView, reload) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/v3/wishlist').then(async (response) => {
                setWishlist(response.data)
                if (!reload) setWishlistView(true)
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                reject(error)
            });
        })
    }

    async getSharedEvents(body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/v3/events/suggested', body).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    //UseAlert(401)
                    //setLoading(false)
                }
                reject(error)
            });
        })
    }

    async sendResponse(eventId, body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/v3/event/' + eventId + '/survey', body).then(async (response) => {
                resolve(response.data.message)
            }).catch(function (error) {
                console.log(error)
                if (error.response.data.Message)
                    reject(error.response.data.Message)
                else if (error.response.data.message)
                    reject(error.response.data.message)

            });
        })
    }


    async addFav(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.post(getApiUrl() + '/v3/wishlist/add/' + id).then(async (response) => {
                UseMessage(200, 'Favorite added successfully!')
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }

    async deleteFav(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.put(getApiUrl() + '/v3/wishlist/remove/' + id).then(async (response) => {
                UseMessage(200, 'Favorite deleted successfully!')
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    async getFilters() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.get(getApiUrl() + '/filters').then(async (response) => {

                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
    async addToCalendar(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getAccessToken();
            axios.put(getApiUrl() + '/v3/event/' + id + '/calendar').then(async (response) => {

                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    console.log('try')
                }
                reject(error)
            });
        })
    }
}