import React from 'react'
import Navbar from './Navbar'
import CenterComponent from './CenterComponent'
import { RetailTrainingService } from '../services/Services'
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { oktaAuth } from '../config';
import { useHistory } from 'react-router-dom';
import { lime } from '@mui/material/colors';

export default function Homepage(props) {


    const [rtS] = React.useState(new RetailTrainingService())
    const [isWishlistView, setWishlistView] = React.useState(false)
    const [isSharedView, setIsSharedView] = React.useState(false)
    const [wishlistLoading, setWishlistLoading] = React.useState(false)
    const [wishlist, setWishlist] = React.useState([])
    const [lastTo, setLastTo] = React.useState('')
    const [sharedEvents, setSharedEvents] = React.useState([])
    const [has_more, setHasMore] = React.useState(true)
    const [auth, setAuth] = React.useState('null')
    const [error, setError] = React.useState(false)
    const [loginProcess, setLogingProcess] = React.useState(false)
    const [eventIDValue, setEventIDValue] = React.useState(null)
    const [filtersTab, setFiltersTab] = React.useState(false)
    const { authState } = useOktaAuth();
    const [filters, setFilters] = React.useState(null)
    const [enabledFilters, setEnabledFilters] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [link, setLink] = React.useState(null)
    const [showfilter, setShowFilter] = React.useState(true)
    const [preselectedTargets, setPreselectedTargets] = React.useState([])

    const getWishlist = () => {
        setIsSharedView(false)
        if (isWishlistView)
            setWishlistView(false)
        else {
            setWishlistLoading(true)
            rtS.getWishlist(setWishlist, setWishlistView, false).then(() => {
                setWishlistLoading(false)
            })
        }
    }

    const reloadWishlist = () => {
        setWishlistLoading(true)
        rtS.getWishlist(setWishlist, setWishlistView, true).then(() => {
            setWishlistLoading(false)
        })
    }
    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/" });



    useEffect(() => {
        let clear_url = window.location.href.split('/login')[0]
        if (clear_url)
            window.history.replaceState({ page: "display_page" }, "display_page", clear_url);


        if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken) {

            rtS.getUser().then((data) => {

                setUser(data)
                setAuth('auth')
                if (data.default_target_ids && data.default_target_ids.length > 0 && data.is_luce_user) {
                    setPreselectedTargets(data.default_target_ids)
                    setEnabledFilters(true)
                }
                if (window.location.pathname && window.location.pathname.split('/').length > 0 && window.location.pathname.split('/')[1] !== '' && window.location.pathname.split('/')[1] !== 'digital_calendar') {
                    setEventIDValue(window.location.pathname.split('/')[1])
                }
                //this is for staging with id
                else if (window.location.pathname && window.location.pathname.split('/').length > 1 && window.location.pathname.split('/')[1] === 'digital_calendar' && window.location.pathname.split('/')[2] !== '')
                    setEventIDValue(window.location.pathname.split('/')[2])
                else {
                    if (localStorage.getItem('tmpID')) {
                        let s = '/' + localStorage.getItem('tmpID')
                        window.history.replaceState({ page: "display_page" }, "display_page", s);
                        setEventIDValue(localStorage.getItem('tmpID'))
                    }
                    else {
                        //this is for staging without id
                        if (window.location.hostname === 'hrintegration.globalbit.gucci')
                            window.history.pushState({ page: "display_page" }, "display_page", '/digital_calendar/');
                        else
                            window.history.pushState({ page: "display_page" }, "display_page", '/');
                    }

                }

                if (!filters) {
                    rtS.getFilters().then((data) => {
                        setFilters(data)
                    })
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    if (error.response.data.code === "unauthorized") {
                        setAuth('notauth')
                    }
                    else if (error.response.data.code === "expired_token") {
                        if (!sessionStorage.getItem('ref')) {
                            sessionStorage.setItem('ref', 'true')
                            window.location.reload()
                        }
                    }
                }
            })
            setTimeout(() => {
                localStorage.removeItem('tmpID')

            }, 500);
        }
        else if (!authState?.isAuthenticated) {
            if (!window.location.href.includes('code') && window.location.pathname && window.location.pathname.split('/').length > 0) {
                localStorage.setItem('tmpID', window.location.pathname.split('/')[1])
                Promise.resolve(loggingIn())
            }

        }


    }, [])

    const getSharedEventMore = () => {

        setWishlistLoading(true)
        setIsSharedView(true)
        let todate = new Date()
        todate.setMonth(11)
        todate.setDate(31)
        todate.setFullYear(lastTo.getFullYear() + 1)
        setLastTo(todate)
        let body = {
            search_text: "",
            from_date: lastTo,
            to_date: todate.toISOString().split('T')[0]
        }
        rtS.getSharedEvents(body).then((res) => {
            var b = []
            b = sharedEvents.events
            let monthValues = []
            setHasMore(res.has_more)
            res.events.map((e) => {

                if (!monthValues.includes(new Date(e.start_date).toLocaleString('default', { month: 'long' }))) {
                    e.firstOfMonth = true
                    monthValues.push(new Date(e.start_date).toLocaleString('default', { month: 'long' }))
                }
                else {
                    e.firstOfMonth = false
                }

                b.push(e)
            })

            let shared = {
                'count': b.length,
                'events': b
            }
            setSharedEvents(shared)
            setWishlistLoading(false)
        })
    }
    const getSharedEvents = () => {

        if (!isSharedView) {
            setWishlistLoading(true)
            setIsSharedView(true)
            setWishlistView(false)
            let todate = new Date()
            todate.setMonth(11)
            todate.setDate(31)
            setLastTo(todate)
            let body = {
                search_text: "",
                from_date: new Date().toISOString().split('T')[0],
                to_date: todate.toISOString().split('T')[0]
            }
            rtS.getSharedEvents(body).then((res) => {
                let monthValues = []
                res.events.map((e) => {
                    if (!monthValues.includes(new Date(e.start_date).toLocaleString('default', { month: 'long' }))) {
                        e.firstOfMonth = true
                        monthValues.push(new Date(e.start_date).toLocaleString('default', { month: 'long' }))
                    }
                    else {
                        e.firstOfMonth = false
                    }
                })
                setSharedEvents(res)
                setWishlistLoading(false)
            })
        }
        else {
            setIsSharedView(false)
            setLastTo('')
        }
    }
    const openFilterTab = () => {
        if (filtersTab) {
            setFiltersTab(false)
        }
        else {
            if (!filters) {
                rtS.getFilters().then((data) => {
                    setFilters(data)
                    setFiltersTab(true)
                })
            }
            else
                setFiltersTab(true)
        }
    }
    let ref = window.location.origin
    return (
        auth === 'notauth' ? <div className="homepage"> <div className='notauth'> You are not authorized!</div></div> :
            (authState?.isAuthenticated && auth !== 'null' || auth === 'auth' ?
                <>
                    <div className="homepage">
                        <Navbar showfilter={showfilter} link={link} user={user} enabledFilters={enabledFilters} filtersTab={filtersTab} openFilterTab={openFilterTab} isSharedView={isSharedView} getSharedEvents={getSharedEvents} getWishlist={getWishlist} setWishlistView={setWishlistView} isWishlistView={isWishlistView} />
                        <CenterComponent preselectedTargets={preselectedTargets} setShowFilter={setShowFilter} setLink={setLink} setEnabledFilters={setEnabledFilters} filters={filters} setFilters={setFilters} filtersTab={filtersTab} setFiltersTab={setFiltersTab} eventIDValue={eventIDValue} setSharedEvents={setSharedEvents} getSharedEvents={getSharedEvents} has_more={has_more} getSharedEventMore={getSharedEventMore} sharedEvents={sharedEvents} isSharedView={isSharedView} setIsSharedView={setIsSharedView} getWishlist={getWishlist} isWishlistView={isWishlistView} setWishlistView={setWishlistView} wishlist={wishlist} setWishlistEvents={setWishlist} wishlistLoading={wishlistLoading} reloadWishlist={reloadWishlist} />
                    </div>
                </>
                : (auth === 'auth' ?
                    <>
                        <div className='navbar'>
                            <div className='flex navbar_container'>
                                <div></div>

                                <div id="navbar_logo_container">

                                    <div id="navbar_gucci_logo"></div>
                                    <div id="navbar_subtitle">Training Calendar</div>
                                </div></div></div>
                        <div className="homepage"> <div className='error'> <div Style={'text-align:center'}>Error</div><br />
                            <div className='notauth'>  Please  <a href={ref} Style={'color:black'}>click here</a> to login again!</div>
                        </div></div></> : ''))

    )
}