import React, { Component } from 'react';
import { RetailTrainingService } from "../services/Services"

export let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

class CustomToolbar extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let { localizer: { messages }, label } = this.props
        return (
            <div className="rbc-toolbar">
                <div id="left_arrow_calendar" className="pointer" onClick={this.navigate.bind(null, navigate.PREVIOUS, label)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                </div>
                <div className='month_name_calendar_container'>
                    <div className="month_name">{label.split(' ')[0]}</div>
                    <div className="year">{label.split(' ')[1]}</div>
                </div>
                <div className='courses_number_calendar_container'>
                    <div className="month_name">{this.props.allEventsCount}</div>
                    <div className="year">{window.location.hostname !== 'retailtrainingcalendar.gucci.cn' && window.location.hostname !== 'retailtrainingcalendar.gucci.com' ? 'news' : 'courses'}</div>
                </div>
                <div id="right_arrow_calendar" className="pointer" onClick={this.navigate.bind(null, navigate.NEXT, label)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </div>
        )
    }
    navigate = (action, label) => {
        this.props.changeMonth(label, action)
        this.props.onNavigate(action)
    }
}
export default CustomToolbar