import { Route, Routes, useNavigate } from "react-router-dom";
import { LoginCallback, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import Homepage from "./view/Homepage";
import TrackPage from "./view/TrackPage";
import { oktaAuth } from "./config";
import LoginManager from "./LoginManager";
import SurveyPage from "./view/SurveyPage";
import { useEffect } from "react";
import { ErrorComponent } from "./view/ErrorComponent";

const MyRoutes = () => {

	const history = useNavigate();
	const originalUri = async (_oktaAuth, originalUri) => {
		history(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri} >
			<Routes >
				<Route path="/track/:eventId" element={<TrackPage />} />
				<Route path="/survey/:eventId" element={<SurveyPage />} />
				<Route path="/:eventId" element={<Homepage />} />
				<Route path="/login/callback" element={<LoginCallback errorComponent={ErrorComponent} />} />
				<Route path="/" exact={true} element={<Homepage />} />
			</Routes>
		</Security>
	);
};
export default MyRoutes;