import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";

//alert with custom message
export function UseMessage(status, mess) {

    const message = useToast({
        message: mess,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 4000,
        progressBar: true,
        icon: status == 200 ? 'success' : 'warning',
        // color: status == 200 ? '#00c64afa' : '#b90000',
        color: '#000000',
        messageColor: '#ffffff',
        closeOnClick: true
    });
    return message();
}