import React, { useEffect } from 'react'
import { oktaAuth } from '../config';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Typography } from "@mui/material";
import QRCode from 'react-qr-code'
import { UseMessage } from './Alert';
export default function Navbar(props) {



    const logout = async () => {
        oktaAuth.signOut({ originalUri: "/" }).then(() => {
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
        });
    }
    const getSubTitle = () => {
        if (window.location.hostname === 'retailtrainingcalendar.gucci.cn' || window.location.hostname === 'retailtrainingcalendar.gucci.com'
            //      || window.location.hostname === 'hrintegration.globalbit.gucci'
        )
            return 'Training Calendar'
        else
            return 'Gucci Luce News'
    }
    useEffect(() => { }, [props.enabledFilters])

    return (
        <div className='navbar'>
            <div className='flex navbar_container'>
                <div></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" class="bi bi-box-arrow-right center-align ml-10" viewBox="0 0 16 16" onClick={() => logout()}>
                    <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                    <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <div id="navbar_logo_container">

                    <div id="navbar_gucci_logo"></div>
                    <div id="navbar_subtitle">
                        {getSubTitle()} </div>
                </div>
                <div id="user_icon_container"  >

                    {window.location.hostname !== 'retailtrainingcalendar.gucci.cn' && window.location.hostname !== 'retailtrainingcalendar.gucci.com' && props.showfilter
                        //&&                        window.location.hostname !== 'hrintegration.globalbit.gucci'
                        ?
                        (props.enabledFilters ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-funnel-fill mr-10 pointer" viewBox="0 0 16 16" onClick={() => props.openFilterTab()}>
                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-funnel mr-10 pointer" viewBox="0 0 16 16" onClick={() => props.openFilterTab()}>
                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                        </svg>)
                        : ''}
                    {window.location.hostname === 'retailtrainingcalendar.gucci.cn' || window.location.hostname === 'retailtrainingcalendar.gucci.com'
                        // || window.location.hostname === 'localhost'
                        ?
                        < React.Fragment >
                            {
                                props.isSharedView ?
                                    <img className={'pointer'} src={require('../resources/img/notification-fill.png')} width={30} height={30} onClick={() => props.getSharedEvents()} />
                                    : <img className={'pointer'} src={require('../resources/img/notification.png')} width={30} height={30} onClick={() => props.getSharedEvents()} />
                            }

                            {props.isWishlistView ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-heart-fill  ml-10 pointer" viewBox="0 0 16 16" onClick={() => props.getWishlist()}>
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-heart  ml-10 pointer" viewBox="0 0 16 16" onClick={() => props.getWishlist()}>
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>}
                        </React.Fragment> : ''}
                </div>
            </div>

        </div >
    )
}