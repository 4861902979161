import React, { useEffect } from 'react'
import { RetailTrainingService } from '../services/Services'
import { useOktaAuth } from '@okta/okta-react';
import { oktaAuth } from '../config';


export default function TrackPage(props) {

    const { authState } = useOktaAuth();
    const [auth, setAuth] = React.useState('null')
    const [loading, setLoading] = React.useState(true)
    const [message, setMessage] = React.useState('')
    const loggingIn = async () => {
        oktaAuth.signInWithRedirect({ originalUri: "/track/" + window.location.pathname.split('/track/')[1] });
    }
    useEffect(() => {
        if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken) {

            let rtS = new RetailTrainingService()
            let id = window.location.pathname.split('/track/')[1].replace('/', '')
            if (id)
                rtS.trackPage(id).then((res) => {
                    setMessage(res)
                }).catch((error) => {
                    if (error.response.status == 401) {
                        if (error.response.data.code === "unauthorized") {
                            setAuth('notauth')
                        }
                        else if (error.response.data.code === "expired_token") {
                            if (!sessionStorage.getItem('ref')) {
                                sessionStorage.setItem('ref', 'true')
                                window.location.reload()
                            }
                        }
                    }
                }).finally(() => {
                    setLoading(false)
                })
        } else if (!authState?.isAuthenticated)
            if (!window.location.href.includes('code') && window.location.pathname && window.location.pathname.split('/').length > 0) {
                localStorage.setItem('tmpID', window.location.pathname.split('/')[1])
                Promise.resolve(loggingIn())
            }

    }, [])

    return (
        loading ? <div className="loader-cont">
            <div className="loader1"></div>
        </div> :
            (auth === 'notauth' ?
                <div className="homepage">
                    <div className='notauth'> You are not authorized!</div>
                </div>

                : <div className='track-container' >
                    <div className='track-message'> {message}
                    </div>
                </div>)

    )
}