import OktaAuth from "@okta/okta-auth-js";

export const oktaConfig = {
    issuer: `https://signin.kering.com/oauth2/default`,
    clientId: "0oa8berrnxBfgpw6g417",
    redirectUri: window.location.hostname === 'retailtrainingcalendar.gucci.cn' ? 'https://retailtrainingcalendar.gucci.cn/login/callback/' :
        (window.location.hostname === 'retailtrainingcalendar.gucci.com' ? `https://retailtrainingcalendar.gucci.com/login/callback/` :
            (window.location.hostname === 'lucenewscalendar.gucci.com' ? 'https://lucenewscalendar.gucci.com/login/callback/' :
                (window.location.hostname === 'lucenewscalendar.gucci.cn' ? 'https://lucenewscalendar.gucci.cn/login/callback/' :
                    (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/digital_calendar/login/callback/' :
                        'http://localhost:3000/login/callback/')))),

    postRedirectUri: window.location.hostname === 'retailtrainingcalendar.gucci.cn' ? 'https://retailtrainingcalendar.gucci.cn/login/callback/' :
        (window.location.hostname === 'retailtrainingcalendar.gucci.com' ? `https://retailtrainingcalendar.gucci.com/login/callback/` :
            (window.location.hostname === 'lucenewscalendar.gucci.com' ? 'https://lucenewscalendar.gucci.com/login/callback/' :
                (window.location.hostname === 'lucenewscalendar.gucci.cn' ? 'https://lucenewscalendar.gucci.cn/login/callback/' :
                    (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/digital_calendar/login/callback/' :
                        'http://localhost:3000/login/callback/')))),
    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    tokenManager: {
        autoRenew: true,
    }


};
export const oktaAuth = new OktaAuth(oktaConfig);