import './App.css';
import { BrowserRouter } from "react-router-dom";
import MyRoutes from './MyRoutes';

function App() {

  return (
    <BrowserRouter basename={window.location.hostname === 'hrintegration.globalbit.gucci' ? '/digital_calendar/' : ''}>
      <MyRoutes />
    </BrowserRouter>
  );

}

export default App;
